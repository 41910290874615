import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, LinkBox, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				CycleCare Pro
			</title>
			<meta name={"description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:title"} content={"CycleCare Pro"} />
			<meta property={"og:description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4">
				0677324809
			</Override>
			<Override slot="link3">
				вул. І. Миколайчука, м. Львів
			</Override>
		</Components.Header2>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,#0c1128 100%),url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-1.jpg?v=2024-06-24T13:31:01.528Z) 0% 0%/cover">
			<Override slot="text">
				Ласкаво просимо до CycleCare Pro
			</Override>
			<Override slot="text1">
				CycleCare Pro було засновано з пристрастю до велосипедів і відданістю наданню видатних послуг з ремонту. Наша команда складається з кваліфікованих техніків із багаторічним досвідом роботи у велоіндустрії. Ми розуміємо тонкощі кожного велосипеда, від гірських до шосейних, і ставимося до кожного з них з особливою увагою. У CycleCare Pro ми не просто ремонтуємо велосипеди – ми покращуємо їх продуктивність і продовжуємо термін служби.
			</Override>
			<Override slot="box3" />
			<Override slot="box1" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="text3">
				вул. І. Миколайчука, м. Львів
			</Override>
			<Override slot="text2">
				0677324809
			</Override>
		</Components.Hero>
		<Section
			padding="80px 0 90px 0"
			quarkly-title="Headline-4"
			border-width="0 0 .1px 0"
			border-style="solid"
			border-color="--color-darkL2"
		>
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="--headline2"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Про нас
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 25px 0px">
						CycleCare Pro було засновано з пристрастю до велосипедів і відданістю наданню видатних послуг з ремонту. Наша команда складається з кваліфікованих техніків із багаторічним досвідом роботи у велоіндустрії. Ми розуміємо тонкощі кожного велосипеда, від гірських до шосейних, і ставимося до кожного з них з особливою увагою. У CycleCare Pro ми не просто ремонтуємо велосипеди – ми покращуємо їх продуктивність і продовжуємо термін служби.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text
					margin="0px 0px 25px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					Чому варто вибрати CycleCare Pro?
				</Text>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base" width="70%">
					Важливо вибрати правильне місце для ремонту велосипеда.{" "}
					<br />
					Ось чому CycleCare Pro виділяється:
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Технічні експерти
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Наша команда складається з сертифікованих професіоналів, які захоплюються велоспортом і мають глибоке розуміння велосипедної механіки.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/1-2.jpg?v=2024-06-24T13:31:01.511Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Якісні деталі
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ми використовуємо лише найякісніші деталі для ремонту, забезпечуючи довговічність і продуктивність.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-2.jpg?v=2024-06-24T13:31:01.463Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Комплексні послуги
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							від планового технічного обслуговування до комплексного ремонту, ми пропонуємо повний спектр послуг, щоб задовольнити всі ваші потреби в велосипеді.
						</Text>
					</LinkBox>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/1-3.jpg?v=2024-06-24T13:31:01.465Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Підхід, орієнтований на клієнта
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ми віддаємо перевагу нашим клієнтам, пропонуючи індивідуальне обслуговування та чітку комунікацію протягом усього процесу ремонту.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1561414927-6d86591d0c4f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Доступна ціна
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ми надаємо послуги найвищого рівня за конкурентоспроможними цінами, забезпечуючи найкраще співвідношення ціни та якості.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1600066976008-861b400976ed?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Гнучкий графік
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ми працюємо щодня з 10:00 до 21:00
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Наші Послуги
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					У CycleCare Pro ми пропонуємо широкий спектр послуг, спрямованих на підтримку вашого велосипеда в ідеальному стані.
					<br />
					Наші послуги включають:
					<br />
					<br />
					{"\n"}Налаштування: Регулярне налаштування, щоб ваш велосипед працював безперебійно.{"\n"}
					<br />
					<br />
					Регулювання гальм: Переконайтеся, що ваші гальма чутливі та безпечні.
					<br />
					<br />
					{"\n"}Оптимізація перемикання передач:  Удосконалення перемикання передач для плавної їзди.{"\n"}
					<br />
					<br />
					Розрівнювання коліс: Зберігайте колеса прямими та надійними для оптимальної роботи.{"\n"}
					<br />
					<br />
					Повний ремонт: Комплексний ремонт велосипедів, які потребують повного оновлення.
				</Text>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Header2>
			<Override slot="text">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					CycleCare Pro
				</Strong>
			</Override>
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});